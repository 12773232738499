import classNames from 'classnames';
import { memo } from 'react';

import { StatusTrackingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneList/StatusTrackingAvatar';
import { PaneItem } from '~/components/FileStatusTrackingPane/PaneItem';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { getSubtitleWithProgress } from '~/components/FileStatusTrackingPane/utils';

import { paneListItemClassNames, UploaderPaneListItemSharedProps } from '../shared';

export type CompletedPaneListItemProps = UploaderPaneListItemSharedProps;

export const CompletedPaneListItem = memo(
  ({
    item: { ext, onRowClick, previewLoader, avatar, title, thumbnailSource, sizeInBytes, subtitle, status },
  }: CompletedPaneListItemProps) => {
    return (
      <PaneItem
        onClick={onRowClick}
        className={classNames('opacity-60', paneListItemClassNames, !!onRowClick && 'cursor-pointer hover:bg-grey-3')}
        previewLoader={previewLoader}
        avatar={avatar ?? <StatusTrackingAvatar ext={ext} status={status} thumbnailSource={thumbnailSource} />}
        title={<StatusTrackingTitle>{title}</StatusTrackingTitle>}
        subtitle={
          <StatusTrackingSubtitle className="truncate">
            {subtitle ?? getSubtitleWithProgress({ title: 'Completed', uploadedBytes: sizeInBytes, sizeInBytes })}
          </StatusTrackingSubtitle>
        }
        buttons={null}
      />
    );
  },
);
CompletedPaneListItem.displayName = 'CompletedPaneListItem';
