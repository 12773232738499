import { Close, Refresh } from '@air/next-icons';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import classNames from 'classnames';
import { memo } from 'react';
import isEqual from 'react-fast-compare';

import { FileTrackingItem } from '~/components/FileStatusTrackingPane/FileStatusTrackingPane/FileStatusTrackingPane';
import { StatusTrackingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneList/StatusTrackingAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItem } from '~/components/FileStatusTrackingPane/PaneItem';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';

import { paneListItemClassNames, UploaderPaneListItemSharedProps } from '../shared';

export interface ErroredPaneListItemProps extends UploaderPaneListItemSharedProps {
  error: string;
  onCancel?: (item: FileTrackingItem) => void;
  onRetry?: (item: FileTrackingItem) => void;
}

export const ErroredPaneListItem = memo(
  ({
    item: { ext, title, thumbnailSource, onRowClick, previewLoader, avatar, status },
    item,
    error,
    onCancel,
    onRetry,
  }: ErroredPaneListItemProps) => (
    <PaneItem
      onClick={onRowClick}
      className={classNames('hover:bg-grey-3', paneListItemClassNames, !!onRowClick && 'cursor-pointer')}
      previewLoader={previewLoader}
      avatar={avatar ?? <StatusTrackingAvatar ext={ext} status={status} thumbnailSource={thumbnailSource} />}
      title={<StatusTrackingTitle>{title}</StatusTrackingTitle>}
      subtitle={
        <StatusTrackingSubtitle className="truncate">
          <VisuallyHidden>with {title}</VisuallyHidden>
          <span aria-hidden="true">Error:</span> {error}
        </StatusTrackingSubtitle>
      }
      buttons={
        <>
          {onRetry && <PaneButton onClick={() => onRetry(item)} Icon={Refresh} label="Retry" />}
          {onCancel && <PaneButton onClick={() => onCancel(item)} Icon={Close} label="Cancel" />}
        </>
      }
    />
  ),
  isEqual,
);
ErroredPaneListItem.displayName = 'ErroredPaneListItem';
